<template>
  <app-timeline>
    <!-- 12 INVOICES HAVE BEEN PAID -->
    <app-timeline-item
      :variant="this.getCursos.length === 0
        ? 'danger'
        : 'success'"
    >
      <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
        <h6>Creación de cursos</h6>
        <!-- <small class="text-muted">12 cursos</small> -->
      </div>
      <p>{{this.getCursos.length}} cursos creados</p>
      <!-- {{ this.getCursos.length}} -->
      <b-button
        v-b-toggle.report-list
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        size="sm"
        variant="outline-success"
        :to="{ name: 'cursos'}"
      >
        Ver cursos
      </b-button>
    </app-timeline-item>

    <app-timeline-item variant="danger">

      <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
        <h6>Asignación asignaturas de docentes</h6>
        <!-- <small class="text-muted">45 horarios</small> -->
      </div>
      <p>45 docentes con asignaturas asignadas</p>
      <b-button
        v-if="this.getCursos.length !== 0"
        v-b-toggle.report-list
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        size="sm"
        variant="outline-success"
        :to="{ name: 'usuarios'}"
      >
        Ver docentes
      </b-button>
    </app-timeline-item>

    <app-timeline-item variant="danger">

      <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
        <h6>Promoción alumnos</h6>
        <!-- <small class="text-muted">30 alumnos</small> -->
      </div>
      <p>30 alumnos</p>
      <b-button
        v-if="this.getCursos.length !== 0"
        v-b-toggle.report-list
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        size="sm"
        variant="outline-success"
        :to="{ name: 'alumnos'}"
      >
        Ver alumnos
      </b-button>
    </app-timeline-item>

  </app-timeline>
</template>

<script>
import {
  BButton, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'

import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BButton,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  computed: {
    ...mapGetters({ getCursos: 'cursos/getCursos' }),
  },
  mounted() {
    this.cargaCursos()
  },
  methods: {
    ...mapActions({
      fetchCursos: 'cursos/fetchCursos',
    }),
    cargaCursos() {
      this.fetchCursos().then(() => {
        // this.cargaCurso
      })
    },
  },
}
</script>
