<template>
  <div class="text-center">
    <!-- cargando -->
    <b-overlay
      :show="false"
      spinner-variant="primary"
      variant="lighy"
    >
      <b-button
        v-if="showBoton"
        variant="primary"
        class="mb-1 mb-sm-0 mt-0 btn-sm-block text-center"
        @click="actualizarDatos"
      >
        Actualizar Notas
      </b-button>

    </b-overlay>
    <vue-apex-charts
      v-if="showChart"
      type="radialBar"
      height="245"
      class="my-2"
      variant="primary"
      :options="goalOverviewRadialBar"
      :series="series"
    />
    <b-card-text class="text-muted mb-0">
      {{ textoChart}}
    </b-card-text>
    <ul class="mt-2 text-left">
      <li v-for="(alerta, key) in alertas" :key="key" :class="alerta.estado === 1 ? 'text-success' : 'text-danger'" style="list-style:none">
        {{ alerta.titulo }}: {{ alerta.texto }}
      </li>
    </ul>
    <b-row class="text-center mx-0">
      <b-col
        cols="6"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          Total Actualizaciones
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ footer.total }}
        </h3>
      </b-col>

      <b-col
        cols="6"
        class="border-top d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          Fecha última actualización
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ footer.fecha }}
        </h3>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BCardText, BOverlay,
} from 'bootstrap-vue'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'
import { formatos } from '@core/mixins/ui/formatos'

import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#51e5a8'

export default {
  components: {
    BButton,
    BOverlay,
    VueApexCharts,
    BRow,
    BCardText,
    BCol,
  },
  mixins: [formatos],
  data() {
    return {
      cargando: false,
      showBoton: false,
      showChart: false,
      textoChart: '',
      alertas: [],
      series: [0],
      footer: {
        total: 1,
        fecha: '12-10 13:50',
      },
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: '2.86rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getUserLibroDigital: 'authLibroDigital/user',
      authErrorMessage: 'authLibroDigital/authErrorMessage',

      authLibroDigitalToken: 'authLibroDigital/authLibroDigitalToken',
      getNotasAll: 'notas/getNotasAll',
      getCursosActivosEstablecimiento: 'cursos/getCursosActivosEstablecimiento',
      getLdConexiones: 'dashLdConexion/getLdConexiones',
    }),
    alertSinAsignaturas() {
      return this.tipoEnseñanzas.length
    },
  },
  mounted() {
    if (!this.getUser) {
      this.fetchAttempt().then(() => {
        this.cargaConexiones()
      })
    } else {
      this.cargaConexiones()
    }
  },
  methods: {
    ...mapActions({
      fetchAttempt: 'auth/attempt',
      fetchCursosActivosEstablecimiento: 'cursos/fetchCursosActivosEstablecimiento',
      signInLibroDigital: 'authLibroDigital/signInLibroDigital',
      fetchAll: 'notas/fetchAll',
      updateNotasLibroDigital: 'notas/updateNotasLibroDigital',
      fetchLdConexiones: 'dashLdConexion/fetchLdConexiones',
      addLdConexion: 'dashLdConexion/addLdConexion',
    }),
    agregarLog() {
      const logs = []

      this.alertas.forEach(alerta => {
        logs.push({
          state: alerta.estado,
          message: alerta.titulo,
          nombreCurso: alerta.texto,
        })
      })
      const data = {
        idPeriodo: this.getUser.idPeriodoActivo,
        logs,
      }
      this.addLdConexion(data).then(() => {

      })
    },
    actualizarDatos() {
      this.cargando = true
      this.showChart = true
      this.textoChart = 'Actualizando notas...'

      this.fetchCursosActivosEstablecimiento(this.getUser.idEstablecimientoActivo).then(() => {
        const data = {
          rut: this.getUser.rut,
          password: '123456',
        }
        this.signInLibroDigital(data).then(() => {
          this.alertas = []
          if (typeof this.getLibroDigital === 'undefined' && this.authErrorMessage) {
            this.alertas.push({
              estado: 2,
              titulo: 'Error de conexión!',
              texto: `${this.authErrorMessage} - A fallado la conexión con el libro digital, contactese con soporte.`,
            })
            this.cargando = false
          } else {
            localStorage.setItem('userDataLibroDigital', JSON.stringify(this.getUserLibroDigital))
            let count = 0
            const countCursos = this.getCursosActivosEstablecimiento.length
            this.mensaje('Inicio')
            this.getCursosActivosEstablecimiento.forEach(curso => {
              const datos = {
                idPeriodo: this.getUser.idPeriodoActivo,
                idCurso: curso.id,
              }

              this.fetchAll(datos).then(() => {
                const nombreCurso = `${curso.nombreGrado} ${curso.letra}`
                if (this.getNotasAll.length === 0) {
                  this.alertas.push({
                    estado: 2,
                    titulo: nombreCurso,
                    texto: 'Curso sin notas.',
                  })
                  count = 1 + count
                  this.chartPorcentaje(count, countCursos)
                  if (countCursos === count) {
                    this.cargando = false
                    this.finalizar()
                  }
                } else {
                  // ACTUALIZA NOTAS
                  const datosNotas = {
                    notas: this.getNotasAll,
                    user: this.getUserLibroDigital,
                    token: this.authLibroDigitalToken,
                  }
                  this.updateNotasLibroDigital(datosNotas).then(() => {
                    const {
                      status,
                      message,
                    } = store.state.notas
                    if (status === 'Success') {
                      this.alertas.push({
                        estado: 1,
                        titulo: nombreCurso,
                        texto: message,
                      })
                    } else {
                      this.alertas.push({
                        estado: 2,
                        titulo: nombreCurso,
                        texto: message,
                      })
                    }
                    count = 1 + count
                    this.chartPorcentaje(count, countCursos)
                    if (countCursos === count) {
                      this.cargando = false
                      this.finalizar()
                    }
                  })
                }
              })
            })
          }
        })
      })
    },
    finalizar() {
      this.mensaje('Termino')
      this.agregarLog()
    },
    mensaje(tipo) {
      const title = tipo === 'Inicio'
        ? 'Actualizando información 🔄'
        : 'Información actualizada'

      const text = tipo === 'Inicio'
        ? 'El traspaso de notas Brújula > Libro Digital a comenzado, este proceso podría demorar un par de minutos!'
        : 'El traspaso de notas Brújula > Libro Digital a finalizado!'
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'CheckIcon',
          text,
          variant: 'success',
        },
      },
      {
        timeout: 10000,
        position: 'top-center',
      })
    },

    chartPorcentaje(progreso, countCursos) {
      this.series = []
      const porcentaje = (progreso / countCursos) * 100
      if (porcentaje === 100) {
        this.textoChart = 'Notas actualizadas...'
      }
      this.series.push(Math.trunc(porcentaje))
    },
    cargaConexiones() {
      this.showBoton = false
      if (this.getUser.rolActivo === 'Director' || this.getUser.rolActivo === 'Jefe UTP') {
        this.showBoton = true
        this.fetchLdConexiones(this.getUser.idPeriodoActivo).then(() => {
          let fechaCompara = null
          let fechaMayor = null
          this.getLdConexiones.forEach(ld => {
            const fechaBd = ld.created_at
            fechaCompara = fechaCompara === null ? fechaBd : fechaCompara
            fechaMayor = fechaCompara < fechaBd ? fechaBd : fechaCompara
          })
          fechaMayor = this.formatoFechaLdConexion(fechaMayor)
          // const ldConexion = this.getLdConexiones.find( ld => ld.created_at === fechaMayor)
          this.footer.total = this.getLdConexiones.length
          this.footer.fecha = fechaMayor
        })
      }
    },
  },
}
</script>
